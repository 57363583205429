class SortsTabContent1920 {
    constructor(parentSelector, categoryTitle, maxHeatingArea, id, boilerModel, boilerNumber, pageLink, imgSrc) {
        this.parent = document.querySelector(parentSelector);
        this.categoryTitle = categoryTitle;
        this.maxHeatingArea = maxHeatingArea;
        this.id = id;
        this.boilerModel = boilerModel;
        this.boilerNumber = boilerNumber;
        this.pageLink = pageLink;
        this.imgSrc = imgSrc;
    };

    render() {
        const tabheader = document.createElement('div');
        tabheader.classList.add('heat__wrapper');

        tabheader.innerHTML = `
            <div class="heat__left">
                <h2 class="heat__title titleH2">${this.categoryTitle}</h2>
                <p class="heat__area">
                    <span class="heat__area-value">${this.maxHeatingArea}</span> м2
                </p>
                <span class="heat__area-descr">Площадь отапливаемого помещения</span>
                <br>
                <div class="rangeContainer">
                    <span class="range__min">5 м²</span>
                    <input 
                        type="range" 
                        min="5" 
                        max="${this.maxHeatingArea}" 
                        value="${this.maxHeatingArea}" 
                        id="range__${this.id}" 
                        class="range"
                        data-range="${this.id}">
                    <span class="range__max">${this.maxHeatingArea} м²</span>
                </div>
                <p class="heat__model">
                    ${this.boilerModel}-<span class="heat__model-value">${this.boilerNumber}</span>А 
                </p>
                <span class="heat__model-descr">Рекомендованная модель котла</span>
            </div>
            <a href="/produkt/${this.pageLink}" class="heat__img-link" target="_blank">
                <img class="heat__img" src="${this.imgSrc}" alt="${this.categoryTitle}">
            </a>
            <ul class="heat__list"> 
                <li class="heat__item">
                    <b>Работает без электричества</b>
                    <span>Подключение к электросети не требуется</span>
                </li>
                <li class="heat__item">
                    <b>Сталь 3 мм.</b>
                    <span>От надёжных российских производителей</span>
                </li>
                <li class="heat__item">
                    <b>Подходит для систем с естественной и принудительной циркуляцией</b>
                </li>
                <li class="heat__item">
                    <b>Европейские комплектующие</b>
                    <span>Газовая автоматика SIT — Италия Контрольно-измерительные приборы CEWAL — Италия</span>
                </li>
                <li class="heat__item">
                    <b>Атмосферная горелка из нержавеющей стали</b>
                </li>
                <li class="heat__item">
                    <b>Срок службы 15 лет</b>
                </li>
            </ul>
        `;

        this.parent.append(tabheader);
    };
};

export default SortsTabContent1920;