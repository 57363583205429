'use strict';
import toggleHamburger from './modules/toggleHamburger';
import stopPhoneNumber from './modules/stopPhoneNumber';
import activateSlider from './modules/slider';
import toggleFilterView from './modules/toggleFilterView';
import submitForm from './modules/submitForm';
import toggleModal from './modules/toggleModal';

import initSectionSorts from './modules/initSectionSorts';
import initSectionCatalogueOnPageMain from './modules/initSectionCatalogueOnPageMain';
import initSectionShopsOnPageMain from './modules/initSectionShopsOnPageMain';
import initSectionServiceCentresOnPageMain from './modules/initSectionServiceCentresOnPageMain';

window.addEventListener('DOMContentLoaded', () => {
    toggleHamburger({
        hamburgerMenuSelector: '.header__list', 
        hamburgerMenuActiveSelector: 'header__list_active',
        hamburgerMenuItemSelector: '.header__item',
        hamburgerSelector: '.header__hamburger',
        hamburgerActiveSelector: 'header__hamburger_active'
    });

    stopPhoneNumber();

    toggleFilterView(
        '.catalogue__filter-title', 
        '.catalogue__filter', 
        'catalogue__filter-title_active', 
        'catalogue__filter_active'
    );

    activateSlider({
        slide: '.promoSlider__wrapper_left .promoSlider__item',
        prevArrow: null,
        nextArrow: null,
        wrapper: '.promoSlider__wrapper_left',
        field: '.promoSlider__wrapper_left .promoSlider',
        automatically: true
    });

    activateSlider({
        slide: '.promoSlider__wrapper_right .promoSlider__item',
        prevArrow: null,
        nextArrow: null,
        wrapper: '.promoSlider__wrapper_right',
        field: '.promoSlider__wrapper_right .promoSlider',
        automatically: true
    });

    // главная/для чего вы хотите использовать ваш котел?
    // общая функция, которая активирует все скрипты
    initSectionSorts();

    // главная/каталог
    // общая функция, которая активирует все скрипты внутри этой секции
    initSectionCatalogueOnPageMain();

    // обновление товаров в каталоге по клику на кнопку "применить фильтр"
    document.querySelector('.filter__activate').addEventListener('click', () => {
        initSectionCatalogueOnPageMain();
    });

    // главная/информация/магазины
    // общая функция, которая активирует все скрипты внутри этой секции
    initSectionShopsOnPageMain();

    // главная/информация/сервисные центры
    // общая функция, которая активирует все скрипты внутри этой секции
    initSectionServiceCentresOnPageMain();

    submitForm(
        '.modal__form', 
        '.modal__form input[type="text"]', 
        '.modal__form textarea', 
        '.modal_question', 
        '.modal_error', 
        '.modal_thanks', 
        '../public/mailer/question.php',
        '.modal__form input[type="tel"]'
    );

    toggleModal('.modal_question', '.service__link_question');

    toggleHamburger({
        hamburgerMenuSelector: '.footer__list_1600', 
        hamburgerMenuActiveSelector: 'footer__list_active',
        hamburgerMenuItemSelector: '.footer__item',
        hamburgerSelector: '.footer__hamburger',
        hamburgerActiveSelector: 'footer__hamburger_active'
    });  
});




