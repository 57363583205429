function activateFilter(filterCheckboxSelector, filterCheckboxAllSelector, filterActivateSelector) {
    const filterCheckboxAll = document.querySelector(filterCheckboxAllSelector);
    const filterCheckbox = document.querySelectorAll(filterCheckboxSelector);
    const filterActivateBtn = document.querySelector(filterActivateSelector);

    function checkAll() {
        filterCheckboxAll.addEventListener('input', () => {
            // если чекбокс "все" активен
            if (filterCheckboxAll.checked) { 
                // то проверяется каждый чекбокс
                filterCheckbox.forEach(filterCheckbox => {
                    // и если он не активен
                    if (filterCheckbox.checked === false) {
                        // то он становится активным
                        filterCheckbox.checked = true;
                    }
                });
            } else if (filterCheckboxAll.checked === false) {
                // то проверяется каждый чекбокс
                filterCheckbox.forEach(filterCheckbox => {
                    // и если он активен
                    if (filterCheckbox.checked === true) {
                        // то он становится не активным
                        filterCheckbox.checked = false;
                    }
                });
            }
        });
    };

    checkAll();

    // функция, которая делает активным чекбокс "все", если все остальные чекбоксы активны
    function checkCheckboxAll() {
        // счетчик количества активных чекбоксов
        let counter = 0;

        filterCheckbox.forEach(checkbox => {
            // если чекбокс активен
            if (checkbox.checked === true) {
                // то счетчик увеличивается
                counter++;
            } 
        });

        // если значение счетчика равно количеству чекбоксов, т.е. все чекбоксы активны
        if (counter === filterCheckbox.length - 1) {
            // то чекбокс "все" тоже становится активным
            filterCheckboxAll.checked = true;
        }

        // если все чекбоксы не активны
        if (counter === 0) {
            // то кнопка "применить фильтр" тоже не активна
            filterActivateBtn.setAttribute('disabled', 'disabled');
            filterActivateBtn.style.cursor = 'not-allowed'
        } else if (counter > 0) {
            // иначе активна
            filterActivateBtn.removeAttribute('disabled');
            filterActivateBtn.style.cursor = 'pointer';
        }
    }

    filterCheckbox.forEach(checkbox => {
        checkbox.addEventListener('input', () => {
            // если какой-то из чекбоксов не активен
            if (checkbox.checked === false) {
                // то чекбокс "все" тоже не активен
                filterCheckboxAll.checked = false;
            }

            checkCheckboxAll();
        });
    });
}

export default activateFilter;

