import KotelArtekService from '../services/kotelArtekService';
import Spinner from '../components/spinner';
import SortsTabHeader from '../components/sortsTabHeader';
import SortsTabContentItem from '../components/sortsTabContentItem';
import SortsTabContent1920 from '../components/sortsTabContent1920';
import SortsTabContent992 from '../components/sortsTabContent992';
import SortsTabContent320 from '../components/sortsTabContent320';
import initCategories from '../services/initCategories';
import activateTabs from './tabs';
import animateFirstTab from './animateFirstTab';
import activateInputRange from './inputRange';
import getHeatingarea from './getHeatingarea';

// главная/для чего вы хотите использовать ваш котел?
// общая функция, которая активирует все скрипты внутри этой секции
function initSectionSorts() {
    const kotelArtekService = new KotelArtekService(); 
    const spinnerSorts = new Spinner('.sorts .tabheader__list');
    spinnerSorts.render();

    kotelArtekService.getBoilerCategories()
        .then(result => {
            function processResponse(result, i) {
                new SortsTabHeader(
                    '.tabheader__list', 
                    result.data.name
                ).render();

                new SortsTabContentItem().render();

                const boilers = Object.entries(result.data.produktList);
                // максимальная площадь отапливаемого помещения среди котлов в перебираемой категории
                let maxHeatingArea = 0;
                // модель котла с максимальной площадью отапливаемого помещения. отсюда берем только цифры. то есть, если модель КСГ-100а, то забираем только 100
                let maxBoilerNumber = null;
                // котел с максимальной площадью отапливаемого помещения
                let biggestBoiler = null;
                    
                // поиск максимальной площади и котла с максимальной площадью
                boilers.forEach((boiler, i) => {
                    const heatingarea = getHeatingarea(boiler);

                    if (heatingarea > maxHeatingArea) {
                        maxHeatingArea = heatingarea;
                        biggestBoiler = i;
                    };

                    // из КСГ-12,5а оставляем 12.5
                    const boilerNumber = +boiler[1].name.replace(/\p{Alpha}/giu, '').replace(/\-/g, '').replace(/,/g, '.');
                    if (boilerNumber > maxBoilerNumber) {
                        maxBoilerNumber = boilerNumber;
                    };
                });

                // из 12.5 делаем 12,5
                maxBoilerNumber = String(maxBoilerNumber).replace(/\./g, ',');

                // из КСГ-12,5а достаем КСГ
                const boilerModelFull = boilers[0][1].name.replace(/\d/g, '').replace(/\-/g, '');
                const boilerModel = boilerModelFull.slice(0, boilerModelFull.length - 1);

                new SortsTabContent1920(
                    `.sorts .tabcontent__item:nth-child(${i + 1})`,
                    result.data.name,
                    maxHeatingArea, 
                    result.data.id,
                    boilerModel,
                    maxBoilerNumber,
                    boilers[biggestBoiler][1].url,
                    Object.entries(boilers[biggestBoiler][1].media)[0][1].fileurl
                ).render();

                new SortsTabContent992(
                    `.sorts .tabcontent__item:nth-child(${i + 1})`,
                    result.data.name,
                    maxHeatingArea, 
                    result.data.id,
                    boilerModel,
                    maxBoilerNumber,
                    boilers[biggestBoiler][1].url,
                    Object.entries(boilers[biggestBoiler][1].media)[0][1].fileurl
                ).render();

                new SortsTabContent320(
                    `.sorts .tabcontent__item:nth-child(${i + 1})`,
                    result.data.name,
                    maxHeatingArea, 
                    result.data.id,
                    boilerModel,
                    maxBoilerNumber,
                    boilers[biggestBoiler][1].url,
                    Object.entries(boilers[biggestBoiler][1].media)[0][1].fileurl
                ).render();

                // сортировка массива котлов по площади отпаливаемого помещения. от меньшей к большей
                let sortedBoilers = [];
                let heatingareas = [];

                boilers.forEach(boiler => {
                    heatingareas.push(getHeatingarea(boiler))
                });

                heatingareas.sort((a, b) => a - b);

                heatingareas.forEach(area => {
                    boilers.forEach(boiler => {
                        if (area === getHeatingarea(boiler)) {
                            sortedBoilers.push(boiler);
                        }
                    });
                });
                
                activateInputRange(
                    `[data-range="${result.data.id}"]`, 
                    `.sorts .tabcontent__item:nth-child(${i + 1}) .heat__area-value`, 
                    `.sorts .tabcontent__item:nth-child(${i + 1}) .heat__model-value`, 
                    `.sorts .tabcontent__item:nth-child(${i + 1}) .heat__img`, 
                    `.sorts .tabcontent__item:nth-child(${i + 1}) .heat__img-link`,
                    sortedBoilers
                );
            }

            async function activateEverything() {
                await initCategories(processResponse, Object.entries(result.data), spinnerSorts);

                // // анимация для первого таба
                // document.querySelector('.sorts .tabcontent__item:first-child .heat__wrapper:first-child .heat__left').classList.add('heat__left_animated');
                // document.querySelector('.sorts .tabcontent__item:first-child .heat__wrapper:first-child .heat__img').classList.add('heat__img_animated');
                // document.querySelector('.sorts .tabcontent__item:first-child .heat__wrapper:first-child .heat__list').classList.add('heat__list_animated');

                // // анимация для первого таба на 992
                // document.querySelector('.sorts .tabcontent__item:first-child .heat__wrapper_992 .heat__left').classList.add('heat__left_animated');
                // document.querySelector('.sorts .tabcontent__item:first-child .heat__wrapper_992 .heat__img').classList.add('heat__img_animated');
                // document.querySelector('.sorts .tabcontent__item:first-child .heat__wrapper_992 .heat__bottom').classList.add('heat__bottom_animated');

                activateTabs('.tabheader__item', '.tabcontent__item', '.tabheader__list', 'tabheader__item_active');

                animateFirstTab();
            }

            activateEverything();
        });
}

export default initSectionSorts;