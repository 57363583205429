class BoilerCardPreview {
    constructor(parentSelector, cardClass, 
        linkClass, imgClass, titleClass, descrClass, categoryString, areaClass,
        boilerUrl, imgUrl, alt, title, power, area) {
        this.parent = document.querySelector(parentSelector);
        this.cardClass = cardClass;
        this.linkClass = linkClass;
        this.imgClass = imgClass;
        this.titleClass = titleClass;
        this.descrClass = descrClass;
        this.categoryString = categoryString;
        this.areaClass = areaClass;
        this.boilerUrl = boilerUrl;
        this.imgUrl = imgUrl;
        this.alt = alt;
        this.title = title;
        this.power = power;
        this.area = area;
    };

    render() {
        const card = document.createElement('li');
        card.classList.add(this.cardClass);

        card.innerHTML = `
            <a href="${this.boilerUrl}" class="${this.linkClass}">
                <img src="${this.imgUrl}" alt="${this.alt}" class="${this.imgClass}">
            </a>
            <h4 class="${this.titleClass}">${this.title}</h4>
            <p class="${this.descrClass}">
                ${this.categoryString}
                <br>
                <span> ${this.power}</span>  
            </p>
            <br>
            <span class="${this.areaClass}">${this.area}</span>
        `;

        this.parent.append(card);
    };
};

export default BoilerCardPreview;