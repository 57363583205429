class KotelArtekService {
    constructor() {
    };

    async getResource(url) {
        const response = await fetch(`/api/v1/${url}`);
    
        if (!response.ok) {
            throw new Error(`Could not fetch ${url}, received ${response.status}`)
        }

        return await response.json();
    };

    // все категории котлов
    async getBoilerCategories() {
        const result = await this.getResource(`category/aktiveparentlist`);

        return result;
    };

    // все категории запчастей
    async getSparesCategories() {
        const result = await this.getResource(`category/childlist/6`);

        return result;
    }; 

    async getCategory(id) {
        const result = await this.getResource(`category/Produkt/${id}`);

        return result;
    }; 

    async getAllShops() {
        const result = await this.getResource(`shoplist/getall?limit=50`);

        return result;
    };

    async getAllServiceCentres() {
        const result = await this.getResource(`serveslist/getall?limit=50`);

        return result;
    };

    async getAllNews() {
        const result = await this.getResource(`news/getall?limit=50`);

        return result;
    };
};

export default KotelArtekService;