import initCategory from './initCategory';

// функция-обертка для получения всех категорий
async function initCategories(processResponse, categories, spinner) {
    // из каждой категории мы вынимаем id этой категории (categories[i][0]), передаем его в initCategory() и дальше в getCategory() и затем уже получаем информацию о конкретной категории
    for (let i = 0; i < categories.length; i++) {
        await initCategory(processResponse, categories[i][0], i);
    }

    // после запросов на сервер удаляем спиннер
    spinner.remove();
}

export default initCategories;