import KotelArtekService from '../services/kotelArtekService';
import Spinner from '../components/spinner';
import RegionsList from '../components/regionsList';
import ItemsList from '../components/itemsList';
import Shop from '../components/shop';
import activateSelect from './select';

// главная/информация/магазины
// общая функция, которая активирует все скрипты внутри этой секции
function initSectionShopsOnPageMain() {
    const kotelArtekService = new KotelArtekService(); 
    const spinnerShops = new Spinner('.shopsCities__list');
    spinnerShops.render();

    // 1) магазины
    kotelArtekService.getAllShops()
        .then(result => {
            // после запросов на сервер удаляем спиннер
            spinnerShops.remove();

            const shopArr = Object.entries(result.data);
            let regions = [];

            for (let i = 0; i < shopArr.length; i++) {
                if (!regions.includes(shopArr[i][1].region)) {
                    regions.push(shopArr[i][1].region)
                }
            }

            regions = regions.sort();

            for (let i = 0; i < regions.length; i++) {
                // рисуем список регионов
                new RegionsList(
                    '.shops__select-body', 
                    `${regions[i]}`,
                    'shops__select-item'
                ).render();

                // рисуем обертки для каждого списка магазинов в каждом регионе
                new ItemsList(
                    '.shopsCities__list',
                    'shopsCities__item',
                    'shops__list'
                ).render();
            }

            // рисуем магазины
            for (let i = 0; i < shopArr.length; i++) {
                for (let j = 0; j < regions.length; j++) {
                    // если регион i-ого магазина совпадает с названием j-ого региона
                    if (shopArr[i][1].region === regions[j]) {
                        // то в списке магазинов j-ого региона рисуем магазин
                        new Shop(
                            `.shopsCities__item:nth-child(${j + 1}) .shops__list`,
                            `${shopArr[i][1].name}`,
                            `${shopArr[i][1].addres}`,
                            `${shopArr[i][1].worktime}`,
                            `${shopArr[i][1].phone}`,
                            `${shopArr[i][1].urladdress}`
                        ).render();
                    }
                }
            }

            // первый регион в списке магазинов должен быть активным по умолчанию при первичной загрузке страницы
            document.querySelector('.shopsCities__item:nth-child(1)').classList.add('shopsCities__item_active');
            document.querySelector('.shopsCities__item:nth-child(1) .shops__list').classList.add('shops__list_active');

            activateSelect({
                selectHeaderSelector: '.shops__select-header', 
                selectItemSelector: '.shops__select-item', 
                selectCurrentSelector: '.shops__select-current', 
                selectActive: 'shops__select_active', 
                selectCurrentActive: 'shops__select-current_active',
                selectSelector: '.shops__select',
                citiesItemSelector: '.shopsCities__item',
                citiesItemActiveSelector: 'shopsCities__item_active'
            });
        });
}

export default initSectionShopsOnPageMain;