class RegionsList {
    constructor(parentSelector, regionName, regionClass) {
        this.parent = document.querySelector(parentSelector);
        this.regionName = regionName;
        this.regionClass = regionClass;
    };

    render() {
        const region = document.createElement('div');
        region.classList.add(this.regionClass);

        region.innerHTML = this.regionName;

        this.parent.append(region);
    };
};

export default RegionsList;