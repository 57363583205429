import KotelArtekService from '../services/kotelArtekService';
import Spinner from '../components/spinner';
import RegionsList from '../components/regionsList';
import ItemsList from '../components/itemsList';
import ServiceCentre from '../components/serviceCentre';
import activateSelect from './select';

// главная/информация/сервисные центры
// общая функция, которая активирует все скрипты внутри этой секции
function initSectionServiceCentresOnPageMain() {
    const kotelArtekService = new KotelArtekService(); 
    const spinnerServiceCentres = new Spinner('.serviceCities__list');
    spinnerServiceCentres.render();

    // 2) сервисные центры
    kotelArtekService.getAllServiceCentres()
        .then(result => {
            // после запросов на сервер удаляем спиннер
            spinnerServiceCentres.remove();

            // массив всех сервисных центров, которые пришли с сервера
            const serviceCentresArr = Object.entries(result.data);
            let regions = [];

            for (let i = 0; i < serviceCentresArr.length; i++) {
                // если в массиве регионов нет региона, в котором представлен i-ый сервисный центр, то мы добавляем этот регион в этот массив
                if (!regions.includes(serviceCentresArr[i][1].region)) {
                    regions.push(serviceCentresArr[i][1].region)
                }
            }

            regions = regions.sort();

            for (let i = 0; i < regions.length; i++) {
                // рисуем список регионов
                new RegionsList(
                    '.service__select-body', 
                    `${regions[i]}`,
                    'service__select-item'
                ).render();

                // рисуем обертки для каждого списка сервисных центров в каждом регионе
                new ItemsList(
                    '.serviceCities__list',
                    'serviceCities__item',
                    'service__centres'
                ).render();
            }

            // рисуем сервисные центры
            for (let i = 0; i < serviceCentresArr.length; i++) {
                for (let j = 0; j < regions.length; j++) {
                    // если регион i-ого сервисного центра совпадает с названием j-ого региона
                    if (serviceCentresArr[i][1].region === regions[j]) {
                        // то в списке сервисных центров j-ого региона рисуем сервисный центр
                        new ServiceCentre(
                            `.serviceCities__item:nth-child(${j + 1}) .service__centres`,
                            `${serviceCentresArr[i][1].name}`,
                            `${serviceCentresArr[i][1].addres}`,
                            `${serviceCentresArr[i][1].phone}`,
                            `${serviceCentresArr[i][1].urladdress}`
                        ).render();
                    }
                }
            }

            // первый регион в списке сервисных центров должен быть активным по умолчанию при первичной загрузке страницы
            document.querySelector('.serviceCities__item:nth-child(1)').classList.add('serviceCities__item_active');
            document.querySelector('.serviceCities__item:nth-child(1) .service__centres').classList.add('service__centres_active');

            activateSelect({
                selectHeaderSelector: '.service__select-header', 
                selectItemSelector: '.service__select-item', 
                selectCurrentSelector: '.service__select-current', 
                selectActive: 'service__select_active', 
                selectCurrentActive: 'service__select-current_active',
                selectSelector: '.service__select',
                citiesItemSelector: '.serviceCities__item',
                citiesItemActiveSelector: 'serviceCities__item_active'
            });
        });
}

export default initSectionServiceCentresOnPageMain;