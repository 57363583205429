import KotelArtekService from './kotelArtekService';

// функция-обертка для получения категории 
// processResponse - это функция для обработки тех данных от сервера, которые будут получены
// id - id категории (не совпадает с номером по порядку)
// i - номер категории по порядку
async function initCategory(processResponse, id, i) {
    const kotelArtekService = new KotelArtekService();

    await kotelArtekService.getCategory(id)
        .then(data => {
            processResponse(data, i)
        })
}

export default initCategory;