class BoilerCardSlide {
    constructor(parentSelector, slideClass, listClass) {
        this.parent = document.querySelector(parentSelector);
        this.slideClass = slideClass;
        this.listClass = listClass;
    };

    render() {
        const slide = document.createElement('li');
        slide.classList.add(this.slideClass);

        slide.innerHTML = `<ul class="${this.listClass}"></ul>`;

        this.parent.append(slide);
    };
};

export default BoilerCardSlide;