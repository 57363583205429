class SortsTabContent992 {
    constructor(parentSelector, categoryTitle, maxHeatingArea, id, boilerModel, boilerNumber, pageLink, imgSrc) {
        this.parent = document.querySelector(parentSelector);
        this.categoryTitle = categoryTitle;
        this.maxHeatingArea = maxHeatingArea;
        this.id = id;
        this.boilerModel = boilerModel;
        this.boilerNumber = boilerNumber;
        this.pageLink = pageLink;
        this.imgSrc = imgSrc;
    };

    render() {
        const tabheader = document.createElement('div');
        tabheader.classList.add('heat__wrapper', 'heat__wrapper_992');

        tabheader.innerHTML = `
            <div class="heat__top">
                <div class="heat__left">
                    <h2 class="heat__title titleH2">${this.categoryTitle}</h2>
                    <ul class="heat__list"> 
                        <li class="heat__item">
                            <b>Работает без электричества</b>
                            <span>Подключение к электросети не требуется</span>
                        </li>
                        <li class="heat__item">
                            <b>Сталь 3 мм. или чугун</b>
                            <span>От надёжных российских производителей</span>
                        </li>
                        <li class="heat__item">
                            <b>Подходит для систем с естественной и принудительной циркуляцией</b>
                        </li>
                        <li class="heat__item">
                            <b>Европейские комплектующие</b>
                            <span>Газовая автоматика SIT — Италия Контрольно-измерительные приборы CEWAL — Италия</span>
                        </li>
                        <li class="heat__item">
                            <b>Атмосферная горелка из нержавеющей стали</b>
                        </li>
                        <li class="heat__item">
                            <b>Срок службы 15 лет</b>
                        </li>
                    </ul>
                </div>
                <a href="/produkt/${this.pageLink}" class="heat__img-link" target="_blank">
                    <img class="heat__img" src="${this.imgSrc}" alt="${this.categoryTitle}">
                </a>
            </div>
            <div class="heat__bottom">
                <div class="heat__bottom-top">
                    <div class="heat__bottom-left">
                        <p class="heat__area">
                            <span class="heat__area-value heat__area-value_992">${this.maxHeatingArea}</span> м2
                        </p>
                        <span class="heat__area-descr">Площадь отапливаемого помещения</span>
                    </div>
                    <div class="heat__bottom-right">
                        <p class="heat__model">
                        ${this.boilerModel}-<span class="heat__model-value heat__model-value_992">${this.boilerNumber}</span>А 
                        </p>
                        <span class="heat__model-descr">Рекомендованная модель котла</span> 
                    </div>
                </div>
                <div class="rangeContainer rangeContainer">
                    <span class="range__min">5 м²</span>
                    <input 
                        type="range" 
                        min="5" 
                        max="${this.maxHeatingArea}" 
                        value="${this.maxHeatingArea}" 
                        id="range__992_${this.id}" 
                        class="range range_992"
                        data-range="${this.id}">
                    <span class="range__max">${this.maxHeatingArea} м²</span>
                </div>
            </div>
        `;

        this.parent.append(tabheader);
    };
};

export default SortsTabContent992;