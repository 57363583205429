function stopPhoneNumber() {
    const headerCallMarquee = document.querySelector('.header__call marquee');
    const headerCallNumber = document.querySelector('.header__call-number');

    headerCallMarquee.addEventListener('mouseover', () => {
        headerCallMarquee.style.display = 'none';
        headerCallNumber.style.display = 'inline';
    });

    headerCallNumber.addEventListener('mouseout', () => {
        headerCallNumber.style.display = 'none';
        headerCallMarquee.style.display = 'inline-block';
    });
}

export default stopPhoneNumber;