function toggleHamburger({
    hamburgerMenuSelector,
    hamburgerMenuActiveSelector,
    hamburgerMenuItemSelector,
    hamburgerSelector,
    hamburgerActiveSelector
    }) {
    const hamburgerMenu = document.querySelector(hamburgerMenuSelector);
    const hamburgerMenuItem = document.querySelectorAll(hamburgerMenuItemSelector);
    const hamburger = document.querySelector(hamburgerSelector);

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle(hamburgerActiveSelector);
        hamburgerMenu.classList.toggle(hamburgerMenuActiveSelector);

        if (document.body.style.overflow === "") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    });

    if (hamburger === '.header__hamburger') {
        hamburgerMenuItem.forEach(item => {
            item.addEventListener('click', () => {
                hamburger.classList.toggle(hamburgerActiveSelector);
                hamburgerMenu.classList.toggle(hamburgerMenuActiveSelector);
            })
        });
    }
}

export default toggleHamburger;