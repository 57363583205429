function animateFirstTab() {
    const heatLefts = document.querySelectorAll('.heat__left_animated');
    const heatImgs = document.querySelectorAll('.heat__img_animated');
    const heatLists = document.querySelectorAll('.heat__list_animated');
    const heatBottoms = document.querySelectorAll('.heat__bottom_animated');
    const heatWrappers = document.querySelectorAll('.heat__wrapper');
    const heatLeftActive = 'heat__left_animated-active';
    const heatImgActive = 'heat__img_animated-active';
    const heatListActive = 'heat__list_animated-active';
    const heatBottomActive = 'heat__bottom_animated-active';

    function animOnScroll(items, itemActiveClass) {
        function getOffset(el) {
            const rect = el.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            return {top: rect.top + scrollTop};
        }

        heatWrappers.forEach(heatWrapper => {
            const heatWrapperHeight = heatWrapper.offsetHeight;
            const heatWrapperOffset = getOffset(heatWrapper).top;
            const animStart = 1.5;

            let heatWrapperPoint = window.innerHeight - heatWrapperHeight / animStart;
            if (heatWrapperHeight > window.innerHeight) {
                heatWrapperPoint = window.innerHeight - window.innerHeight / animStart;
            }

            if ((pageYOffset > heatWrapperOffset - heatWrapperPoint) && pageYOffset < (heatWrapperOffset + heatWrapperHeight)) {
                items.forEach(item => {
                    item.classList.add(itemActiveClass);
                });
            } else {
                // items.forEach(item => {
                //     item.classList.remove(itemActiveClass);
                // });
            }
        });
    }

    window.addEventListener('scroll', animOnScroll.bind(this, heatLefts, heatLeftActive));
    window.addEventListener('scroll', animOnScroll.bind(this, heatImgs, heatImgActive));
    window.addEventListener('scroll', animOnScroll.bind(this, heatLists, heatListActive));
    window.addEventListener('scroll', animOnScroll.bind(this, heatBottoms, heatBottomActive));
}

export default animateFirstTab;