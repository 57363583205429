class SortsTabHeader {
    constructor(parentSelector, title) {
        this.parent = document.querySelector(parentSelector);
        this.title = title;
    };

    render() {
        const tabheader = document.createElement('li');
        tabheader.classList.add('tabheader__item');

        tabheader.innerHTML = `
            <h4 class="tabheader__item-title titleH4">Отопление</h4>
            <span class="tabheader__item-descr">${this.title}</span>
        `;

        this.parent.append(tabheader);
    };
};

export default SortsTabHeader;