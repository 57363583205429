// import openModal from './toggleModal';
// import closeModal from './toggleModal';

// ниже следует повторение двух функций из файла toggleModal.js с той лишь разницей, что в файле toggleModal.js
// const modal = modalElem;
// а в этом
// const modal = document.querySelector(modalSelector);
// не получилось сделать без повторения

function openModal(modalSelector) {
    const modal = document.querySelector(modalSelector);
    const hamburger = document.querySelector('.footer__hamburger-wrapper');
    const scroll = calcScroll();

    // убирает отступ справа при клике на модальное окно, чтобы когда открывалась модалка, экран не прыгал
    function calcScroll() {
        let div = document.createElement('div');

        div.style.width = '50px';
        div.style.height = '50px';
        div.style.overflowY = 'scroll';
        div.style.visibility = 'hidden';

        document.body.appendChild(div);

        let scrollWidth = div.offsetWidth - div.clientWidth // offsetWidth - полная ширина, clientWidth - паддинги + контент внутри, без прокрутки. таким образом scrollWidth - ширина самой прокрутки
        div.remove();

        return scrollWidth;
    }

    modal.style.display = 'flex';
    document.body.style.overflow = 'hidden'; // запрет прокрутки при открытом мод.окне
    document.body.style.marginRight = `${scroll}px`;
    hamburger.style.zIndex = 'auto';
};

function closeModal(modalSelector, automatically) {
    const modal = document.querySelector(modalSelector);
    const hamburger = document.querySelector('.footer__hamburger-wrapper');

    const close = () => {
        modal.style.display = 'none';
        document.body.style.overflow = '';
        document.body.style.marginRight = 0;
        hamburger.style.zIndex = '15';
    }
    
    // закрытие модального окна при клике на подложку или клике на крестик
    modal.addEventListener('click', (e) => { 
        if (e.target === modal || e.target.getAttribute('data-close') === "") {
            close();
        }
    });
    
    // закрытие модального окна при клике на escape
    document.addEventListener('keydown', (e) => {
        if (e.code === "Escape" && modal.style.display == 'flex') {
            close();
        }
    });

    if (automatically === '') {
        close();
    }
};

function submitForm(
    formSelector, 
    inputTextSelector, 
    textareaSelector, 
    modalMainSelector, 
    modalErrorSelector, 
    modalThanksSelector, 
    url, 
    inputPhoneSelector, 
    inputEmailSelector) {
    const form = document.querySelector(formSelector),
          inputTexts = form.querySelectorAll(inputTextSelector),
          inputPhone = form.querySelector(inputPhoneSelector),
          inputEmail = form.querySelector(inputEmailSelector),
          textarea = form.querySelector(textareaSelector);  
    
    const postData = async (url, data) => {
        let result = await fetch(url, {
            method: "POST",
            body: data
        });

        return await result.text();
    };

    const clear = () => {
        inputTexts.forEach(input => {
            input.value = '';
        });

        if (inputPhone) {
            inputPhone.value = '';
        }

        if (inputEmail) {
            inputEmail.value = '';
        }

        if (textarea) {
            textarea.value = '';
        }
    };

    form.addEventListener('submit', function(e) {
        e.preventDefault();

        // на случай валидации через js
        // const arr = [];
        // inputs.forEach(input => {
        //     arr.push(input.value);
        //     return arr;
        // });
        
        // if (arr.some(i => { // проверка на то, что все инпуты заполнены
        //         return i === '';
        //     })
        //     || textarea && textarea.value === '') { // проверка на то, что textarea заполнен
        //     closeModal(modalMainSelector, '');
        //     openModal(modalNotificationSelector);
        //     closeModal(modalNotificationSelector);
        // } else {
            // без  валидации через js
            const formData = new FormData(form);
            postData(url, formData)
                .then(() => {
                    closeModal(modalMainSelector, '');
                    openModal(modalThanksSelector);
                    closeModal(modalThanksSelector);
                })
                .catch(() => {
                    closeModal(modalMainSelector, '');
                    openModal(modalErrorSelector);
                    closeModal(modalErrorSelector);
                })
                .finally(() => {
                    clear();
                });
        // }
    });
};

export default submitForm;