class FilterItem {
    constructor(id, title) {
        this.neighbour = document.querySelector('.catalogue .filter .filter__activate-wrapper');
        this.id = id;
        this.title = title;
    };

    render() {
        this.neighbour.insertAdjacentHTML('beforebegin', `
            <li class="filter__item">
                <label for="${this.id}" class="filter__checkbox">
                    <input class="filter__checkbox-real" type="checkbox" name="${this.id}" id="${this.id}" checked>
                    <span class="filter__checkbox-fake"></span>
                    <span class="filter__checkbox-title">${this.title}</span>
                </label>
            </li>
        `);
    };
};

export default FilterItem;