class SortsTabContentItem {
    constructor() {
        this.parent = document.querySelector('.sorts .tabcontent__list');
    };

    render() {
        const tabContentItem = document.createElement('li');
        tabContentItem.classList.add('tabcontent__item', 'heat');

        this.parent.append(tabContentItem);
    };
};

export default SortsTabContentItem;