function activateSlider({
    slide, 
    prevArrow, 
    nextArrow, 
    wrapper,
    field,
    startArrow = false, 
    endArrow = false, 
    sliderPageSelector = false,
    automatically,
    modalBtnSelector
    }) {
    const slides = document.querySelectorAll(slide);
    const sliderArrowPrev = document.querySelector(prevArrow);
    const sliderArrowNext = document.querySelector(nextArrow);
    const slidesWrapper = document.querySelector(wrapper);
    const slidesField = document.querySelector(field);
    const sliderArrowStart = document.querySelector(startArrow);
    const sliderArrowEnd = document.querySelector(endArrow);
    const sliderPages = document.querySelectorAll(sliderPageSelector);
    let slidesWrapperWidth = countSlidesWrapperWidth();

    // для того, чтобы карусель работала в модальном окне
    function countSlidesWrapperWidth() {
        let number = window.getComputedStyle(slidesWrapper).width;
        return number;
    }

    if (modalBtnSelector) {
        document.querySelector(modalBtnSelector).addEventListener('click', () => {
            slidesWrapperWidth = countSlidesWrapperWidth();
            return slidesWrapperWidth
        })
    }
    
    let slideIndex = 1;

    slidesField.style.width = 100 * slides.length + '%';
    slidesField.style.transform = 'translateX(0)';
    // если присваивать без setTimeout, то будет небольшой баг
    setTimeout(() => {
        slidesField.style.transition = '0.75s all';
    }, 100);

    slides.forEach(slide => {
        slide.style.width = slidesWrapperWidth;
    });

    let offset = 0; // переменная, которая показывает количество отступа

    // удаление "px" в строке и превращение строки в число
    function deleteNotDigits (str) {
        return +str.replace(/px/g, '');
    }

    function unhighlightSliderPage() {
        sliderPages.forEach(item => {
            item.style.backgroundColor = '#ffffff';
        });
    }

    function highlightSliderPage(item) {
        item.style.backgroundColor = '#FF7D54';
    }

    function changeHighlightSliderPage() {
        sliderPages.forEach((item, i) => {
            if (slideIndex - 1 === i) {
                unhighlightSliderPage();
                highlightSliderPage(item);
            }
        });
    }

    if (sliderArrowNext) {
        sliderArrowNext.addEventListener('click', () => {
            if (offset == deleteNotDigits(slidesWrapperWidth) * (slides.length - 1)) {
                offset = 0;
            } else {
                offset += deleteNotDigits(slidesWrapperWidth);
            }
    
            slidesField.style.transform = `translateX(-${offset}px)`;
    
            if (slideIndex == slides.length) {
                slideIndex = 1;
            } else {
                slideIndex++;
            }
    
            changeHighlightSliderPage();
        });
    }

    if (sliderArrowPrev) {
        sliderArrowPrev.addEventListener('click', () => {
            if (offset == 0) {
                offset = deleteNotDigits(slidesWrapperWidth) * (slides.length - 1);
            } else {
                offset -= deleteNotDigits(slidesWrapperWidth);
            }
    
            slidesField.style.transform = `translateX(-${offset}px)`;
    
            if (slideIndex == 1) {
                slideIndex = slides.length;
            } else {
                slideIndex--;
            }
    
            changeHighlightSliderPage();
        });
    }

    if(sliderArrowStart) {
        sliderArrowStart.addEventListener('click', () => {
            offset = 0;

            slidesField.style.transform = `translateX(-${offset}px)`;
    
            slideIndex = 1;
    
            changeHighlightSliderPage();
        });
    }

    if (sliderArrowEnd) {
        sliderArrowEnd.addEventListener('click', () => {
            offset = deleteNotDigits(slidesWrapperWidth) * (slides.length - 1);
    
            slidesField.style.transform = `translateX(-${offset}px)`;
    
            slideIndex = slides.length;
    
            changeHighlightSliderPage();
        });
    }

    if (sliderPages) {
        sliderPages.forEach(item => {
            item.addEventListener('click', () => {
                slideIndex = item.textContent;

                offset = deleteNotDigits(slidesWrapperWidth) * (slideIndex - 1);

                slidesField.style.transform = `translateX(-${offset}px)`;

                unhighlightSliderPage();
                highlightSliderPage(item);
            });
        });
    }

    if (automatically) {
        setInterval(() => {
            if (offset == deleteNotDigits(slidesWrapperWidth) * (slides.length - 1)) {
                offset = 0;
            } else {
                offset += deleteNotDigits(slidesWrapperWidth);
            }
    
            slidesField.style.transform = `translateX(-${offset}px)`;
    
            if (slideIndex == slides.length) {
                slideIndex = 1;
            } else {
                slideIndex++;
            }
        }, 7000);
    }
}

export default activateSlider;