class ItemsList {
    constructor(parentSelector, itemClass, listClass) {
        this.parent = document.querySelector(parentSelector);
        this.itemClass = itemClass;
        this.listClass = listClass;
    };

    render() {
        const shopList = document.createElement('li');
        shopList.classList.add(this.itemClass);

        shopList.innerHTML = `<ul class="${this.listClass}"></ul>`;

        this.parent.append(shopList);
    };
};

export default ItemsList;