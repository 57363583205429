import getHeatingarea from "./getHeatingarea";

function activateInputRange(rangeSelector, heatAreaSelector, heatModelSelector, heatImgSelector, heatImgLinkSelector, boilers) {
    const ranges = document.querySelectorAll(rangeSelector);
    const heatArea = document.querySelectorAll(heatAreaSelector);
    const heatModel = document.querySelectorAll(heatModelSelector);
    const heatImg = document.querySelectorAll(heatImgSelector);
    const boilerLinks = document.querySelectorAll(heatImgLinkSelector);

    ranges.forEach((range, i) => {
        range.addEventListener('input', () => {
            heatArea[i].textContent = +range.value;

            boilers.forEach(boiler => {
                if (+heatArea[i].textContent >= getHeatingarea(boiler)) {
                    heatModel[i].textContent = boiler[1].name.replace(/\p{Alpha}/giu, '').replace(/\-/g, '');
                    heatImg[i].src = Object.entries(boiler[1].media)[0][1].fileurl;
                    boilerLinks[i].href = `/produkt/${boiler[1].url}`; 
                } 
            });
        });
    });
}

export default activateInputRange;

