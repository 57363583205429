import KotelArtekService from '../services/kotelArtekService';
import Spinner from '../components/spinner';
import FilterItem from '../components/filterItem';
import activateFilter from './filter';
import countSlides from './countSlides';
import BoilerCardSlide from '../components/boilerCardSlide';
import BoilerCardPreview from '../components/boilerCardPreview';
import BoilerSliderPage from '../components/boilerSliderPage';
import initCategories from '../services/initCategories';
import activateSlider from './slider';
import getHeatingarea from './getHeatingarea';

// главная/каталог
// общая функция, которая активирует все скрипты внутри этой секции
function initSectionCatalogueOnPageMain() {
    // перед началом работы
    // удаляем все слайды
    document.querySelectorAll('.catalogue .slider__item').forEach(item => {
        item.remove();
    });

    // удаляем все страницы в пагинации
    document.querySelectorAll('.catalogue .slider__page').forEach(item => {
        item.remove();
    });

    // кнопка "применить фильтр"
    const filterActivateBtn = document.querySelector('.filter__activate')
    // перед и вовремя запроса на сервер эта кнопка не активна
    filterActivateBtn.setAttribute('disabled', 'disabled');
    filterActivateBtn.style.cursor = 'not-allowed';
    // чекбоксы в фильтре вместе с оберткой
    const filterItems = document.querySelectorAll('.catalogue .filter__item');

    const kotelArtekService = new KotelArtekService(); 
    const spinner = new Spinner('.catalogue .slider');
    spinner.render();
    
    // общее количество котлов
    let totalBoilers = 0;
    // общее количество слайдов
    let totalSlides = 0;
    // массив с информацией про котлы 
    let boilers = [];

    kotelArtekService.getBoilerCategories()
        .then(result => {
            // сортированный массив котлов по площади отапливаемого помещения. от большей к меньшей
            let sortedBoilers = [];

            // функция для обработки ответа от сервера
            function processResponse(result) {
                // если количество чекбоксов равно 1 или меньше(1 чекбокс - это чекбокс "все"), значит чекбоксы ещё не нарисованы, и мы их рисуем. иначе ничего не делаем. проверка сделана для того, чтобы не перерисовывать чекбоксы каждый раз при нажатии на кнопку "применить фильтр"
                if (filterItems.length <= 1) {
                    new FilterItem(result.data.id, result.data.name).render();
                }

                // перебираем все чекбоксы
                document.querySelectorAll('.catalogue .filter__item:not(:first-child) .filter__checkbox-real').forEach(checkbox => {
                    // если чекбокс активен и это именно тот чекбокс, который отвечает за категорию, которая к нас сейчас пришла от сервера
                    if (checkbox.checked && checkbox.id === result.data.id) {
                        // увеличиваем количество котлов
                        totalBoilers += result.data.produktcount;
                        // считаем количество слайдов
                        totalSlides = countSlides(totalBoilers, 10); 

                        // массив площадей отапливаемого помещения
                        let heatingareas = [];

                        // перебираются все котлы
                        Object.entries(result.data.produktList).forEach(boiler => {
                            // для каждого котла создается новый ключ category со значением его категории (потому что в самом объекте item[1] она не указана)
                            boiler[1].category = result.data.name;
                            // площадь отапливаемого помещения котла записывается в соответствующий массив
                            heatingareas.push(getHeatingarea(boiler));
                        });

                        // сортировка площадей отапливаемого помещения. от большего к меньшему. 30, 25, 20...
                        heatingareas.sort((a, b) => a - b).reverse();

                        heatingareas.forEach(area => {
                            Object.entries(result.data.produktList).forEach(boiler => {
                                if (area === getHeatingarea(boiler)) {
                                    sortedBoilers.push(boiler[1]);
                                }
                            });
                        });
                    }
                })
            }

            async function activateEverything() {
                await initCategories(processResponse, Object.entries(result.data), spinner);
                // разрешаем нажимать на кнопку "применить фильтр"
                filterActivateBtn.removeAttribute('disabled');
                filterActivateBtn.style.cursor = 'pointer';

                // начинаем рисовать
                for (let x = 1; x <= totalSlides; x++) {
                    // рисуем слайды
                    new BoilerCardSlide('.catalogue .slider', 'slider__item', 'catalogue__list').render();

                    // рисуем котлы
                    sortedBoilers.forEach((item, y) => {
                        // по 10 штук на слайде
                        if (Math.ceil((y + 1) / 10) === x) {
                            new BoilerCardPreview(
                                `.catalogue .slider__item:nth-child(${x}) .catalogue__list`,
                                'catalogue__item',
                                'catalogue__item-link',
                                'catalogue__item-img',
                                'catalogue__item-title',
                                'catalogue__item-descr',
                                item.category,
                                'catalogue__item-area',
                                `produkt/${item.url}`,
                                `${Object.entries(item.media)[0][1].fileurl}`,
                                item.name,
                                item.name,
                                item.mainoptions.power,
                                item.mainoptions.heatingarea
                            ).render();
                        }
                    });

                    // рисуем кнопки в пагинации 
                    new BoilerSliderPage('.catalogue .slider__arrow-right', 'slider__page', x - 1).render();
                }

                // активируем чекбоксы в фильтре
                activateFilter('.filter__checkbox-real', '.filter__checkbox-real_all', '.filter__activate');

                // включаем слайдер
                activateSlider({
                    slide: '.slider__item', 
                    prevArrow: '.slider__arrow-left',
                    nextArrow: '.slider__arrow-right',
                    wrapper: '.slider__wrapper',
                    field: '.slider',
                    startArrow: '.slider__doublearrow-left',
                    endArrow: '.slider__doublearrow-right',
                    sliderPageSelector: '.slider__page'
                });  
            }

            activateEverything();
        })
}

export default initSectionCatalogueOnPageMain;