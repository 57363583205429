class ServiceCentre {
    constructor(parentSelector, title, address, phone, website) {
        this.parent = document.querySelector(parentSelector);
        this.title = title;
        this.address = address;
        this.phone = phone || "не указан";
        this.phoneLink = phone.replace(/[-, \s, \(, \)]/g, '');
        this.website = website;
    };

    render() {
        const serviceCentre = document.createElement('li');
        serviceCentre.classList.add('service__centres-item');

        serviceCentre.innerHTML = `
            <h4 class="service__centres-title titleH4">${this.title}</h4>
            <button class="service__centres-show">Показать на карте</button>
            <a href="/page/service.html" class="service__centres-link">Показать на карте</a>
            <span class="service__centres-address">${this.address}</span>
        `;

        if (this.phone) {
            serviceCentre.insertAdjacentHTML('beforeend', `
                <a href="tel:${this.phoneLink}" class="service__centres-call">${this.phone}</a>
            `);
        }

        if (this.website) {
            serviceCentre.insertAdjacentHTML('beforeend', `
                <a href="${this.website}" class="service__centres-website" target="_blank">${this.website}</a>
            `);
        }

        this.parent.append(serviceCentre);
    };
};

export default ServiceCentre;