class Spinner {
    constructor(parentSelector) {
        this.parent = document.querySelector(parentSelector);
    };

    render() {
        const spinner = document.createElement('div');
        spinner.classList.add('spinner-wrapper');

        spinner.innerHTML = `
            <div class="spinner"></div>
        `;
        
        this.parent.append(spinner);
    };

    remove() {
        document.querySelectorAll('.spinner-wrapper').forEach(spinner => spinner.remove());
    };
};

export default Spinner;