function activateSelect({
    selectHeaderSelector, 
    selectItemSelector, 
    selectCurrentSelector, 
    selectActive, 
    selectCurrentActive, 
    selectSelector, 
    citiesItemSelector,
    citiesItemActiveSelector
    }) {
    const selectHeader = document.querySelector(selectHeaderSelector);
    const selectItem = document.querySelectorAll(selectItemSelector);
    const selectCurrent = document.querySelector(selectCurrentSelector);
    const citiesItems = document.querySelectorAll(citiesItemSelector);
    const citiesItemsActive = citiesItemActiveSelector;

    function selectToggle() {
        // появляется выпадающий список
        this.parentElement.classList.toggle(selectActive);

        // стрелка крутится
        selectCurrent.classList.toggle(selectCurrentActive);
    }

    selectHeader.addEventListener('click', selectToggle);

    selectItem.forEach(function(item, i) {
        item.addEventListener('click', function() {
            // текст селекта, на который произошел клик
            const text = this.textContent;
            const select = this.closest(selectSelector);
            // текст выбранного селекта
            const currentText = this.closest(selectSelector).querySelector(selectCurrentSelector);

            // текст выбранного селекта равен тексту селекта в списке (на который произошел клик)
            currentText.textContent = text;
            // присвоение текста выбранного селекта в невидимый инпут для последующей передачи данных на сервер
            const input = this.closest(selectSelector).querySelector('.select__input');
            if (input) {
                input.value = text;
            }
            // выпадающий список уезжает
            select.classList.remove(selectActive);
            // стрелка крутится
            selectCurrent.classList.remove(selectCurrentActive);

            if(citiesItems) {
                // выключение всех регионов
                citiesItems.forEach(item => {
                    if (item.classList.contains(citiesItemsActive)) {
                        item.classList.remove(citiesItemsActive);
                    }
                });
            
                // включение списка магазинов нужного региона
                citiesItems[i].classList.add(citiesItemsActive);
            }
        });
    });
}

export default activateSelect;