class BoilerSliderPage {
    constructor(neighbourSelector, sliderPageClass, number) {
        this.neighbour = document.querySelector(neighbourSelector);
        this.sliderPageClass = sliderPageClass;
        this.number = number + 1;
    };

    render() {
        const sliderPage = document.createElement('button');
        sliderPage.classList.add(this.sliderPageClass);
        sliderPage.textContent = this.number;
        this.neighbour.insertAdjacentElement('beforebegin', sliderPage);
    };
};

export default BoilerSliderPage;