function toggleFilterView(filterTitlesSelector, filtersSelector, filterTitleActiveSelector, filterActiveSelector) {
    const filterTitles = document.querySelectorAll(filterTitlesSelector);
    const filters = document.querySelectorAll(filtersSelector);

    filterTitles.forEach((filterTitle, i) => {
        filterTitle.addEventListener('click', () => {
            filterTitle.classList.toggle(filterTitleActiveSelector);
            filters[i].classList.toggle(filterActiveSelector);
        });
    });
}

export default toggleFilterView;