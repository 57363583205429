function openModal(modalElem) {
    const modal = modalElem;
    const headerHamburgerWrapper = document.querySelector('.header__hamburger-wrapper');
    const footerHamburger = document.querySelector('.footer__hamburger');
    const footerHamburgerWrapper = document.querySelector('.footer__hamburger-wrapper');
    const scroll = calcScroll();

    // убирает отступ справа при клике на модальное окно, чтобы когда открывалась модалка, экран не прыгал
    function calcScroll() {
        let div = document.createElement('div');

        div.style.width = '50px';
        div.style.height = '50px';
        div.style.overflowY = 'scroll';
        div.style.visibility = 'hidden';

        document.body.appendChild(div);

        let scrollWidth = div.offsetWidth - div.clientWidth // offsetWidth - полная ширина, clientWidth - паддинги + контент внутри, без прокрутки. таким образом scrollWidth - ширина самой прокрутки
        div.remove();

        return scrollWidth;
    }

    modal.style.display = 'flex';
    document.body.style.overflow = 'hidden'; // запрет прокрутки при открытом мод.окне
    document.body.style.marginRight = `${scroll}px`;
    headerHamburgerWrapper.style.zIndex = '1';
    footerHamburger.style.zIndex = 'auto';
    footerHamburgerWrapper.style.zIndex = 'auto';
};

function closeModal(modalElem, automatically) {
    const modal = modalElem;
    const headerHamburgerWrapper = document.querySelector('.header__hamburger-wrapper');
    const footerHamburger = document.querySelector('.footer__hamburger');
    const footerHamburgerWrapper = document.querySelector('.footer__hamburger-wrapper');

    const close = () => {
        modal.style.display = 'none';
        document.body.style.overflow = '';
        document.body.style.marginRight = 0;
        headerHamburgerWrapper.style.zIndex = '10';
        footerHamburger.style.zIndex = '15';
        footerHamburgerWrapper.style.zIndex = '15';
    }
    
    // закрытие модального окна при клике на подложку или клике на крестик
    modal.addEventListener('click', (e) => { 
        if (e.target === modal || e.target.getAttribute('data-close') === "") {
            close();
        }
    });
    
    // закрытие модального окна при клике на escape
    document.addEventListener('keydown', (e) => {
        if (e.code === "Escape" && modal.style.display == 'flex') {
            close();
        }
    });

    if (automatically === '') {
        close();
    }
};

function toggleModal(modalsSelector, triggerSelector) {
    const modals = document.querySelectorAll(modalsSelector);
    const modalTriggersBtns = document.querySelectorAll(triggerSelector);

    modalTriggersBtns.forEach((btn, i) => {
        btn.addEventListener('click', () => {
            openModal(modals[i]);
            closeModal(modals[i]);
        });
    });
}

export default toggleModal;
export {openModal};
export {closeModal};

