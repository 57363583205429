function activateTabs(tabsSelector, tabsContentSelector, tabsParentSelector, activeClass) {
    const tabs = document.querySelectorAll(tabsSelector);
    const tabsContent = document.querySelectorAll(tabsContentSelector);
    const tabsParent = document.querySelector(tabsParentSelector); 
    // const heatLeft = document.querySelectorAll('.heat__left_animated');
    // const heatImg = document.querySelectorAll('.heat__img_animated');
    // const heatList = document.querySelectorAll('.heat__list_animated');
    // const heatBottom = document.querySelectorAll('.heat__bottom_animated');

    function hideTabContent() {
        tabsContent.forEach(item => {
            item.classList.add('hide');
            item.classList.remove('show');
        });

        tabs.forEach(item => {
            item.classList.remove(activeClass);
        });

        // heatLeft.forEach(heatLeft => {
        //     if (heatLeft.classList.contains('heat__left_animated-active')) {
        //         heatLeft.classList.remove('heat__left_animated-active');
        //     }
        // });

        // heatImg.forEach(heatImg => {
        //     if (heatImg.classList.contains('heat__img_animated-active')) {
        //         heatImg.classList.remove('heat__img_animated-active');
        //     }
        // });

        // heatList.forEach(heatList => {
        //     if (heatList.classList.contains('heat__list_animated-active')) {
        //         heatList.classList.remove('heat__list_animated-active');
        //     }
        // });

        // heatBottom.forEach(heatBottom => {
        //     if (heatBottom.classList.contains('heat__bottom_animated-active')) {
        //         heatBottom.classList.remove('heat__bottom_animated-active');
        //     }
        // });
    }

    hideTabContent();

    function showTabContent(i = 0) {
        tabsContent[i].classList.add('show');
        tabsContent[i].classList.remove('hide');
        tabs[i].classList.add(activeClass);

        // плавное появление элементов таба
        // function showBlockWithDelay(blockSelector, blockActiveSelector) {
        //     const blocks = document.querySelectorAll(blockSelector);

        //     blocks.forEach((block, i) => {
        //         block.classList.remove(blockActiveSelector);
        //         blocks[i].classList.add(blockActiveSelector);
        //     });
        // }

        // if (tabsContent[i].classList.contains('heat') && document.documentElement.clientWidth > 575) {
        //     setTimeout(showBlockWithDelay, 500, '.heat__left', 'heat__left_active');
        //     setTimeout(showBlockWithDelay, 1500, '.heat__img', 'heat__img_active');
        //     setTimeout(showBlockWithDelay, 2500, '.heat__list', 'heat__list_active');
        //     setTimeout(showBlockWithDelay, 500, '.heat__left_992', 'heat__left_active');
        //     setTimeout(showBlockWithDelay, 1500, '.heat__img_992', 'heat__img_active');
        //     setTimeout(showBlockWithDelay, 2500, '.heat__bottom', 'heat__bottom_active');
        // }
    }

    showTabContent();

    tabsParent.addEventListener('click', (event) => {
        const target = event.target;
        if (target && target.closest(tabsSelector)) {
            tabs.forEach((item, i) => {
                if (target == item || target.closest(tabsSelector) == item) {
                    hideTabContent();
                    showTabContent(i);
                }
            });
        }
    });
}

export default activateTabs;