class Shop {
    constructor(parentSelector, title, address, worktime, phone, website) {
        this.parent = document.querySelector(parentSelector);
        this.title = title;
        this.address = address;
        this.worktime = worktime || "не указан";
        this.phone = phone || "не указан";
        this.phoneLink = phone.replace(/[-, \s, \(, \)]/g, '');
        this.website = website;
    };

    render() {
        const shop = document.createElement('li');
        shop.classList.add('shops__item');

        shop.innerHTML = `
            <h4 class="shops__item-title titleH4">${this.title}</h4>
            <button class="shops__item-show">Показать на карте</button>
            <a href="/page/shops.html" class="shops__item-link">Показать на карте</a>
            <span class="shops__item-address">${this.address}</span>
            <div class="shops__item-schedule">Режим работы: ${this.worktime}</div>
        `;

        if (this.phone) {
            shop.insertAdjacentHTML('beforeend', `
                <ul class="shops__item-phones">
                    <li class="shops__item-phone">
                        <a href="tel:${this.phoneLink}" class="shops__item-call">${this.phone}</a>
                    </li>
                </ul>
            `);
        }

        if (this.website) {
            shop.insertAdjacentHTML('beforeend', `
                <a href="${this.website}" class="shops__item-website" target="_blank">${this.website}</a>
            `);
        }

        this.parent.append(shop);
    };
};

export default Shop;